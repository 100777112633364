import Vue from 'vue'
import NavigationItem from './NavigationItem'
import NavigationBar from './NavigationBar'
import Page from './AppPage'
import QRCode from './AppQRCode'
import Copyright from './Copyright'
import SearchBar from './SearchBar'

export default {
  install: function() {
    Vue.component('NavigationItem', NavigationItem)
    Vue.component('NavigationBar', NavigationBar)
    Vue.component('AppPage', Page)
    Vue.component('AppQRCode', QRCode)
    Vue.component('Copyright', Copyright)
    Vue.component('SearchBar', SearchBar)
  }
}