<template>
  <div class="full-width mb-20 bg-white" style="">
    <div class="flex flex-column flex-cross-center" style="padding-top: 3.5rem; padding-bottom: 4rem;">
      <div class="flex flex-cross-center">
        <img src="https://shouqu.oss-cn-shanghai.aliyuncs.com/cms/160/images/1647332336925437801.png" class="block mr-10" style="width: 60px; height: 60px;" alt="">
        <div class="text-333 fw-700" style="font-size:30px;">神奇口袋App</div>
      </div>
      
      <div class="mt-10 text-333 fs-16 fw-700" style="">淘宝、京东、拼多多都能打折！</div>
      <div class="mt-20 flex flex-cross-center flex-column">
        <img src="https://shouqu.oss-cn-shanghai.aliyuncs.com/cms/160/images/1647575676405362580.png" alt="" class="block" style="width: 11rem; height: 11rem;">
        <div class="fs-20 fw-600 text-white text-center" style="width: 14rem;height: 4.5rem;line-height: 4.5rem;background: linear-gradient(180deg, #FF9C35 0%, #FF6F24 100%);border-radius: 2px 2px 15px 15px;">
          扫码下载
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AppQRCode",
  data() {
     return {
     }
  },
  created(){
  },
  computed:{
  },
  methods:{
  },
}
</script>
<style lang="css" scoped>

</style>
