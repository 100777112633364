<template>
  <div class="app-page p-rel flex flex-main-between" :id="name">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "AppPage",
  props: {
    name: String
  }
}
</script>
<style scoped>
.app-page {
  width: 1180px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 6rem;
  padding-top: 3rem;
  padding-bottom: 0rem;
}
</style>