import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'

import moment from 'moment'
Vue.prototype.$moment = moment

Vue.config.productionTip = false

import '@/assets/style/lib.css'
import '@/assets/style/main.css'

// 注册自定义的组件
import components from '@/components'
Vue.use(components)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

console.log(process.env)