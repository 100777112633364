<template>
  <div>
    <div class="mt-20 fs-14 text-center">
      <div class="text-9b mt-10">
        <a href="https://help.meiwulist.com/help/sqkd/pub/app-privacy_sqkd.html" class="text-9b" target="_blank">用户注册及隐私政策</a>
      </div>
      <!-- <div class="text-9b mt-10">美迅科技（北京）有限公司</div>
      <div class=" mt-10"><a href="https://beian.miit.gov.cn/" class="text-9b" target="_blank">京ICP备16007160号</a></div> -->
      <div class="text-9b mt-10">美活科技（河北）有限公司</div>
      <div class=" mt-10"><a href="https://beian.miit.gov.cn/" class="text-9b" target="_blank">冀ICP备2022003206号</a></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
     return {
     }
  },
  created(){
  },
  computed:{
  },
  methods:{
  },
}
</script>
<style lang="css" scoped>

</style>
