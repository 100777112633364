<template>
  <div id="app">
    <NavigationBar :navigationItems="navigationItems" :activeIndex="currentChannelIndex" @onNavigationItemClick="onNavigationItemClick($event)" ></NavigationBar>
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  data(){ 
    return {
      title: "神奇口袋",
      currentChannelIndex: 0,
      navigationItems: [
        {
          title: "神爆料",
          link: {name: 'Home', path: '/home'}
        },
        {
          title: "关于我们",
          link: {name: 'Aboutus', path: '/aboutus'}
        }
      ]
    }
  },
  created(){
    
  },
  methods: {
    onNavigationItemClick(data){
      // console.log(this.navigationItems)
      var self = this
      this.navigationItems.forEach((e, i) => {
        if(e.title == data) {
          self.currentChannelIndex = i
        }
      })

    }
  }
}
</script>

<style>

#app {
  background-color: #f6f6f6;
  height: 100vh;
}
</style>
